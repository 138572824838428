var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-back",
      style: {
        background:
          "url(" +
          require("@/assets/images/login_success.gif") +
          ")" +
          "no-repeat"
      }
    },
    [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/login-logo.png"), alt: "" }
      }),
      _c("div", { staticClass: "title" }, [
        _vm._v("智能化多媒体管理和生产系统")
      ]),
      _c(
        "div",
        { staticClass: "forget" },
        [
          _c("div", { staticClass: "forget-title" }, [_vm._v("重置密码")]),
          _c(
            "el-form",
            {
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.Form, rules: _vm.rules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "newpassword", error: _vm.error } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      autocomplete: "new-password",
                      placeholder: "新密码"
                    },
                    model: {
                      value: _vm.Form.newpassword,
                      callback: function($$v) {
                        _vm.$set(_vm.Form, "newpassword", $$v)
                      },
                      expression: "Form.newpassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "confirmpassword", error: _vm.error } },
                [
                  _c("el-input", {
                    attrs: {
                      "auto-complete": "new-password",
                      type: "password",
                      placeholder: "确认新密码"
                    },
                    model: {
                      value: _vm.Form.confirmpassword,
                      callback: function($$v) {
                        _vm.$set(_vm.Form, "confirmpassword", $$v)
                      },
                      expression: "Form.confirmpassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.resetPassword("dynamicValidateForm")
                        }
                      }
                    },
                    [_vm._v("重置密码")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }