<template>
  <!-- 登录背景 -->
  <div
    class="login-back"
    :style="{
      background:
        'url(' +
        require('@/assets/images/login_success.gif') +
        ')' +
        'no-repeat',
    }"
  >
    <!-- 登录logo -->
    <img class="logo" src="@/assets/images/login-logo.png" alt="" />
    <!-- 标题 -->
    <div class="title">智能化多媒体管理和生产系统</div>
    <!-- 找回密码容器 -->
    <div class="forget">
      <!-- 重置密码标题 -->
      <div class="forget-title">重置密码</div>
      <!-- 重置密码表单 -->
      <el-form
        :model="Form"
        ref="dynamicValidateForm"
        class="demo-dynamic"
        :rules="rules"
      >
        <!-- 新密码 -->
        <el-form-item prop="newpassword" :error="error">
          <el-input type="password" autocomplete="new-password" v-model="Form.newpassword" placeholder="新密码"></el-input>

        </el-form-item>
        <!-- 确认新密码 -->
        <el-form-item prop="confirmpassword" :error="error">
          <el-input  auto-complete="new-password" type="password" v-model="Form.confirmpassword" placeholder="确认新密码"></el-input>
        </el-form-item>
        <!-- 重置密码按钮-->
        <el-form-item>
          <el-button type="primary" @click="resetPassword('dynamicValidateForm')"
            >重置密码</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  data () {
    // 密码不能为纯数字跟两次密码相等的自定义验证规则
    var checkPassword = (rule, value, callback) => {
      if (/^\d+$/.test(value)) {
        return callback(new Error('密码不能纯数字'))
      }
      callback()
    }
    var check = (rule, value, callback) => {
      if (this.Form.newpassword === this.Form.confirmpassword) {
        callback()
      } else {
        return callback(new Error('密码不一致'))
      }
    }
    return {
      // 表单验证规则
      rules: {
        newpassword: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          {
            min: 6,
            message: '密码最少为6位',
            trigger: 'blur'
          },
          { validator: checkPassword, trigger: 'resetPassword' },
          { validator: check, trigger: 'resetPassword' }
        ],
        confirmpassword: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: check, trigger: 'resetPassword' }
        ]
      },
      // 表单绑定值
      Form: {
        newpassword: '',
        confirmpassword: ''
      },
      // 表单错误提示信息
      error: ''
    }
  },

  methods: {
    // 重置密码按钮
    resetPassword (formName) {
      this.$refs[formName].validate((valid) => {
        // 通过验证发起重置密码请求
        if (valid) {
          // 发起重置密码请求
          let formData = new FormData();
          formData.append("password1", md5(this.Form.newpassword));
          formData.append("password2", md5(this.Form.confirmpassword));
          formData.append("email", this.$route.query.email);
          formData.append("code", this.$route.query.code);
          this.$http({
            method: 'POST',
            url: 'accounts/reset_password',
            data: formData
          }).then((res) => {
            // console.log(res)
            //  设置成功
            if (res.data.status == 0) {
              // 跳转登录
              this.$router.push('/login')
            }
            //  发送失败
            else if (res.data.status == 1) {
              this.error = res.data.msg
            }
          }).catch(error => console.log(error))
        } else {
          // 没通过验证返回错误，不进行下一步
          return false
        }
      });
    },
  },
  created(){
    // console.log('1')
  }
};


</script>

<style lang="scss" scoped>
//找回密码背景
.login-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% !important;
  // 登录背景logo
  .logo {
    width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
  }
  // 标题
  .title {
   font-size: 1.6vw;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    letter-spacing: 4px;
    opacity: 1;
  }
  // 找回密码容器
  .forget {
    margin-top: 6.41vh;
    width: 532px;
    height: 446px;
    background: rgba(57, 56, 70, 0.6);
    border-radius: 5px;
    .forget-title {
      margin-left: 126px;
      margin-top: 111px;
      color: rgba(255, 255, 255, 0.9);
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    }
    .demo-dynamic {
      width: 280px;
      height: 40px;
      margin-top: 44px;
      margin-left: 126px;
    }
    /deep/.el-input__inner {
      background: #393846;
      border-radius: 5px;
      font-size: 12px;
      font-family: Microsoft YaHei;
font-weight: 400;
      opacity: 0.6;
      color: #fff;
      width: 280px;
      height: 40px;
    }
    /deep/.el-form-item__error {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 30%;
      left: auto;
      right: -120px;
      width: 113px;
    }
    /deep/.el-button--primary {
      width: 280px;
      height: 40px;
    }
    /deep/.el-form-item__content {
      display: flex;
    }
  }
}
</style>
